// extracted by mini-css-extract-plugin
export var mt0 = "aboutUs-module--mt-0--lrGfp";
export var pt0 = "aboutUs-module--pt-0--2fUCi";
export var mb0 = "aboutUs-module--mb-0--2RlqL";
export var pb0 = "aboutUs-module--pb-0--2Y5bc";
export var ml0 = "aboutUs-module--ml-0--3hfmg";
export var pl0 = "aboutUs-module--pl-0--1Liey";
export var mr0 = "aboutUs-module--mr-0--2gfnQ";
export var pr0 = "aboutUs-module--pr-0--1xQ_8";
export var mt5 = "aboutUs-module--mt-5--3qUOi";
export var pt5 = "aboutUs-module--pt-5--1iA7H";
export var mb5 = "aboutUs-module--mb-5--yQr9V";
export var pb5 = "aboutUs-module--pb-5--2XY0l";
export var ml5 = "aboutUs-module--ml-5--9dgMt";
export var pl5 = "aboutUs-module--pl-5--2s29Y";
export var mr5 = "aboutUs-module--mr-5--2ydCe";
export var pr5 = "aboutUs-module--pr-5--2m6kd";
export var mt10 = "aboutUs-module--mt-10--1uhyR";
export var pt10 = "aboutUs-module--pt-10--2CWZh";
export var mb10 = "aboutUs-module--mb-10--YZZh7";
export var pb10 = "aboutUs-module--pb-10--FsT1x";
export var ml10 = "aboutUs-module--ml-10--pNAjG";
export var pl10 = "aboutUs-module--pl-10--1FiPv";
export var mr10 = "aboutUs-module--mr-10--3isxh";
export var pr10 = "aboutUs-module--pr-10--3_3-6";
export var mt15 = "aboutUs-module--mt-15--1B4bI";
export var pt15 = "aboutUs-module--pt-15--3j09Y";
export var mb15 = "aboutUs-module--mb-15--7aePb";
export var pb15 = "aboutUs-module--pb-15--1xzYo";
export var ml15 = "aboutUs-module--ml-15--2Weqf";
export var pl15 = "aboutUs-module--pl-15--1SOgs";
export var mr15 = "aboutUs-module--mr-15--375_J";
export var pr15 = "aboutUs-module--pr-15--16i7_";
export var mt20 = "aboutUs-module--mt-20--3jhNi";
export var pt20 = "aboutUs-module--pt-20--3nY8Q";
export var mb20 = "aboutUs-module--mb-20--1Ikfe";
export var pb20 = "aboutUs-module--pb-20--2hcME";
export var ml20 = "aboutUs-module--ml-20--29ONt";
export var pl20 = "aboutUs-module--pl-20--3ZM-X";
export var mr20 = "aboutUs-module--mr-20--1DsYg";
export var pr20 = "aboutUs-module--pr-20--34USO";
export var mt25 = "aboutUs-module--mt-25--1BT2I";
export var pt25 = "aboutUs-module--pt-25--3WCmC";
export var mb25 = "aboutUs-module--mb-25--1fyyo";
export var pb25 = "aboutUs-module--pb-25--13AJd";
export var ml25 = "aboutUs-module--ml-25--TZ3iJ";
export var pl25 = "aboutUs-module--pl-25--2LYee";
export var mr25 = "aboutUs-module--mr-25--1Ragx";
export var pr25 = "aboutUs-module--pr-25--3958k";
export var mt30 = "aboutUs-module--mt-30--aKZJx";
export var pt30 = "aboutUs-module--pt-30--20saE";
export var mb30 = "aboutUs-module--mb-30--1AgYU";
export var pb30 = "aboutUs-module--pb-30--zQqpM";
export var ml30 = "aboutUs-module--ml-30--1pat-";
export var pl30 = "aboutUs-module--pl-30--1CMkf";
export var mr30 = "aboutUs-module--mr-30--1KpTv";
export var pr30 = "aboutUs-module--pr-30--367zX";
export var mt35 = "aboutUs-module--mt-35--hBJAa";
export var pt35 = "aboutUs-module--pt-35--2tL_4";
export var mb35 = "aboutUs-module--mb-35--2LIcQ";
export var pb35 = "aboutUs-module--pb-35--1J-fo";
export var ml35 = "aboutUs-module--ml-35--13Qiy";
export var pl35 = "aboutUs-module--pl-35--3GBgc";
export var mr35 = "aboutUs-module--mr-35--2MhIK";
export var pr35 = "aboutUs-module--pr-35--3Wsm9";
export var mt40 = "aboutUs-module--mt-40--1aqYE";
export var pt40 = "aboutUs-module--pt-40--72hkC";
export var mb40 = "aboutUs-module--mb-40--2RZIJ";
export var pb40 = "aboutUs-module--pb-40--2w1b2";
export var ml40 = "aboutUs-module--ml-40--2uSxU";
export var pl40 = "aboutUs-module--pl-40--iOB3g";
export var mr40 = "aboutUs-module--mr-40--GHkEM";
export var pr40 = "aboutUs-module--pr-40--2ei76";
export var mt45 = "aboutUs-module--mt-45--2E4he";
export var pt45 = "aboutUs-module--pt-45--1znx8";
export var mb45 = "aboutUs-module--mb-45--3OEiS";
export var pb45 = "aboutUs-module--pb-45--2CG6W";
export var ml45 = "aboutUs-module--ml-45--1cCs_";
export var pl45 = "aboutUs-module--pl-45--1bsd_";
export var mr45 = "aboutUs-module--mr-45--nZ2cA";
export var pr45 = "aboutUs-module--pr-45--XtTqj";
export var mt50 = "aboutUs-module--mt-50--1QsJW";
export var pt50 = "aboutUs-module--pt-50--3MDYI";
export var mb50 = "aboutUs-module--mb-50--15IKc";
export var pb50 = "aboutUs-module--pb-50--ra9JQ";
export var ml50 = "aboutUs-module--ml-50--oulMP";
export var pl50 = "aboutUs-module--pl-50--3mTuk";
export var mr50 = "aboutUs-module--mr-50--2d8kN";
export var pr50 = "aboutUs-module--pr-50--zic7F";
export var mt75 = "aboutUs-module--mt-75--3u0uS";
export var pt75 = "aboutUs-module--pt-75--3oZOA";
export var mb75 = "aboutUs-module--mb-75--3sELL";
export var pb75 = "aboutUs-module--pb-75--2dFVU";
export var ml75 = "aboutUs-module--ml-75--1R2gk";
export var pl75 = "aboutUs-module--pl-75--RYDfV";
export var mr75 = "aboutUs-module--mr-75--32KIP";
export var pr75 = "aboutUs-module--pr-75--2PYA-";
export var mt100 = "aboutUs-module--mt-100--MC3CH";
export var pt100 = "aboutUs-module--pt-100--3SGRQ";
export var mb100 = "aboutUs-module--mb-100--1-7Ug";
export var pb100 = "aboutUs-module--pb-100--2qG9B";
export var ml100 = "aboutUs-module--ml-100--h9cRF";
export var pl100 = "aboutUs-module--pl-100--1ELBp";
export var mr100 = "aboutUs-module--mr-100--1ZKCy";
export var pr100 = "aboutUs-module--pr-100--zl3M6";
export var zIndex1 = "aboutUs-module--z-index-1--3QGOD";
export var zIndex2 = "aboutUs-module--z-index-2--2wKIv";
export var zIndex3 = "aboutUs-module--z-index-3--1f8fm";
export var zIndex4 = "aboutUs-module--z-index-4--jTyvl";
export var zIndex5 = "aboutUs-module--z-index-5--38B1G";
export var zIndex6 = "aboutUs-module--z-index-6--1CWXp";
export var zIndex7 = "aboutUs-module--z-index-7--3cdHs";
export var zIndex8 = "aboutUs-module--z-index-8--3HuLZ";
export var zIndex9 = "aboutUs-module--z-index-9--12RbH";
export var zIndex10 = "aboutUs-module--z-index-10--2yONB";
export var zIndex20 = "aboutUs-module--z-index-20--1mfnS";
export var zIndex30 = "aboutUs-module--z-index-30--2lgcd";
export var zIndex40 = "aboutUs-module--z-index-40--1nBo1";
export var zIndex50 = "aboutUs-module--z-index-50--2zeoM";
export var zIndex60 = "aboutUs-module--z-index-60--ALQ78";
export var zIndex70 = "aboutUs-module--z-index-70--2SfQM";
export var zIndex80 = "aboutUs-module--z-index-80--2wNhE";
export var zIndex90 = "aboutUs-module--z-index-90--2b1e2";
export var zIndex100 = "aboutUs-module--z-index-100--2V4wN";
export var link = "aboutUs-module--link--uRH28";
export var uppercase = "aboutUs-module--uppercase--Yp0UW";
export var more = "aboutUs-module--more--3s7t3";
export var button = "aboutUs-module--button--3egQt";
export var send = "aboutUs-module--send--Qap23";
export var sm = "aboutUs-module--sm--23Ah6";
export var aboutUsPage = "aboutUs-module--aboutUsPage--3erou";
export var descriptionWrap = "aboutUs-module--descriptionWrap--1ldHB";
export var descriptionTitle = "aboutUs-module--descriptionTitle--3ipu1";
export var description = "aboutUs-module--description--34SJW";
export var ourSpeciality = "aboutUs-module--ourSpeciality--2lCkR";
export var map = "aboutUs-module--map--aPNBQ";